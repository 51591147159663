import React from "react"
import Layout from "../components/layout"
import Form from "../components/form"
import styled from "styled-components"
import SEO from "../components/seo"

const Header = styled.h2`
  margin-top: 50px;
`

export default () => (
  <Layout at="inquire" maxWidth={600}>
    <SEO title="Inquire" />
    <Header>Inquire</Header>
    <p>
      We would love to hear from you. If you are interested in working with us
      or would like to ask a question, please fill out the contact form below
      and we will get back to you as soon as possible
    </p>
    <Form />
  </Layout>
)

import React from "react"
import styled, { css } from "styled-components"
import { navigateTo } from "gatsby-link"

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;
  box-sizing: border-box;
`
const InputStyle = css`
  padding: 7.5px 15px;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  text-align: left;
  background: none;
  border: 1px solid #444;
  border-radius: 3px;
  outline: none;
  transition: box-shadow 150ms linear;
  box-shadow: 0 0 0 #555;
  &:focus {
    box-shadow: 0 0 3px #555;
  }
`
const Label = styled.label`
  display: none;
`
const Input = styled.input`
  ${InputStyle}
`
const TextArea = styled.textarea`
  ${InputStyle}
  grid-column: 1 / -1;
`
const Submit = styled.button`
  ${InputStyle}
  &:hover {
    box-shadow: 0 0 3px #555;
  }
`
const HoneyPot = styled.div`
  display: none;
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Form
        name="contact"
        method="POST"
        action="/inquire/thank-you/"
        netlify-honeypot="bot-field"
        data-netlify="true"
        onSubmit={this.handleSubmit}
      >
        <HoneyPot>
          <Label htmlFor="full-name" name="bot-field">
            Don’t fill out this field if you’re a human.
          </Label>
          <Input
            placeholder="name"
            type="text"
            id="full-name"
            name="full-name"
            onChange={this.handleChange}
          />
        </HoneyPot>
        <input
          type="hidden"
          name="form-name"
          value="contact"
          onChange={this.handleChange}
        />
        <Label htmlFor="fname">Name</Label>
        <Input
          placeholder="Name"
          type="text"
          name="name"
          onChange={this.handleChange}
          required
        />
        <Label htmlFor="email">Email</Label>
        <Input
          placeholder="Email Address"
          type="email"
          name="email"
          onChange={this.handleChange}
          required
        />
        <Label htmlFor="phone">Phone</Label>
        <Input
          placeholder="Phone Number"
          type="tel"
          name="phone"
          onChange={this.handleChange}
        />
        <Label htmlFor="message">Message</Label>
        <TextArea
          placeholder="Message"
          name="message"
          onChange={this.handleChange}
          required
        />
        <Submit type="submit" name="submit">
          Submit
        </Submit>
      </Form>
    )
  }
}
